import React, { useEffect, useRef, useState } from "react";
import bodymovin, { AnimationItem } from "lottie-web";

import heroAnimation from "./assets/animation.json";
import introAnimation from "./assets/intro-animation.json";
import {
  AnimationContainer,
  Container,
  HeroContent,
  HeroSubtitle,
  HeroTitle,
} from "./styles";
import { AnimatedTitle } from "../../components/commons/AnimatedTitle";

export const HomepageHero = () => {
  const containerRef = useRef(null);
  const introRef = useRef(null);
  const [animation, setAnimation] = useState<AnimationItem | null>(null);
  const [currentScene, setCurrentScene] = useState<string>("initial");
  const currentSceneRef = useRef<string>();
  currentSceneRef.current = currentScene;

  const handleEnterFrame = (e: any) => {
    if (e?.currentTime) {
      if (e?.currentTime == 28 && currentSceneRef.current === "word-1") {
        let centerButton = document.querySelector<HTMLElement>("#s01_obj01_in");

        // centerButton.addEventListener('mouseenter', () =>
        // handleElementHover('centerButton')
        // );

        centerButton.style.cursor = "pointer";

        centerButton.addEventListener("click", () =>
          handleElementClick("centerButton")
        );
      }
      if (e?.currentTime == 28 && currentSceneRef.current === "word-3") {
        let centerButton = document.querySelector<HTMLElement>("#s01_obj01_in");

        // centerButton.addEventListener('mouseenter', () =>
        // handleElementHover('centerButton')
        // );

        centerButton.style.cursor = "pointer";

        centerButton.addEventListener("click", () =>
          handleElementClick("centerButton")
        );
      }
      if (e?.currentTime == 18 && currentSceneRef.current === "word-2") {
        let centerButton = document.querySelector<HTMLElement>("#s01_obj01_in");

        // centerButton.addEventListener('mouseenter', () =>
        // handleElementHover('centerButton')
        // );
        centerButton.style.cursor = "pointer";

        centerButton.addEventListener("click", () =>
          handleElementClick("centerButton")
        );
      }
      if (e?.currentTime == 31 && currentSceneRef.current === "word-1") {
        setCurrentScene("initial");
        animation.setSegment(0, animation.totalFrames);
        animation.goToAndStop(0);
        animation.goToAndStop(0, true);
      }
      if (e?.currentTime == 31 && currentSceneRef.current === "word-2") {
        setCurrentScene("initial");
        animation.setSegment(0, animation.totalFrames);
        animation.goToAndStop(0);
        animation.goToAndStop(0, true);
      }
      if (e?.currentTime == 31 && currentSceneRef.current === "word-3") {
        setCurrentScene("initial");
        animation.setSegment(0, animation.totalFrames);
        animation.goToAndStop(0);
        animation.goToAndStop(0, true);
      }
    }
  };

  const handleElementHover = (element: string, reverse?: true) => {
    if (animation) {
      if (element === "seamlessButton") {
        if (animation.currentFrame === 0) {
          animation.playSegments([1, 10], true);
          setCurrentScene("hover-1");
        }
      }
      if (element === "growthButton") {
        if (animation.currentFrame === 0) {
          animation.playSegments([82, 89], true);
          setCurrentScene("hover-2");
        }
      }
      if (element === "tirelessButton") {
        if (animation.currentFrame === 0) {
          animation.playSegments([163, 170], true);
          setCurrentScene("hover-3");
        }
      }
      if (element === "centerButton") {
        if (
          animation.currentFrame === 28 &&
          currentSceneRef.current === "word-1"
        ) {
          // animation.playSegments([44, 47], true)
        }
      }
    }
  };

  const handleElementClick = (element: string, reverse?: true) => {
    if (animation) {
      if (element === "seamlessButton") {
        if (currentSceneRef.current === "word-1") {
          animation.playSegments([48, 80], true);
        }
        if (
          currentSceneRef.current === "initial" ||
          currentSceneRef.current === "hover-1"
        ) {
          animation.playSegments([12, 41], true);
          setCurrentScene("word-1");
        }
      }
      if (element === "growthButton") {
        if (currentSceneRef.current === "word-2") {
          animation.playSegments([129, 161], true);
        }
        if (
          currentSceneRef.current === "initial" ||
          currentSceneRef.current === "hover-2"
        ) {
          animation.playSegments([93, 112], true);
          setCurrentScene("word-2");
        }
      }
      if (element === "tirelessButton") {
        if (currentSceneRef.current === "word-3") {
          animation.playSegments([210, 242], true);
        }
        if (
          currentSceneRef.current === "initial" ||
          currentSceneRef.current === "hover-3"
        ) {
          animation.playSegments([174, 203], true);
          setCurrentScene("word-3");
        }
      }
      if (element === "centerButton") {
        if (currentSceneRef.current === "word-1") {
          animation.playSegments([48, 76], true);
        }
        if (currentSceneRef.current === "word-2") {
          animation.playSegments([129, 161], true);
        }
        if (currentSceneRef.current === "word-3") {
          animation.playSegments([210, 242], true);
        }
      }
    }
  };

  const handleElementLeave = (element: string) => {
    if (animation) {
      if (element === "seamlessButton") {
        if (currentSceneRef.current === "hover-1") {
          animation.setDirection(-1);
          animation.playSegments([animation.currentFrame, 0], true);
          setCurrentScene("initial");
        }
      }
      if (element === "growthButton") {
        if (currentSceneRef.current === "hover-2") {
          animation.setDirection(-1);
          animation.playSegments([89, 82], true);
          setCurrentScene("initial");
        }
      }
      if (element === "tirelessButton") {
        if (currentSceneRef.current === "hover-3") {
          animation.setDirection(-1);
          animation.playSegments([170, 163], true);
          setCurrentScene("initial");
        }
      }
    }
  };

  const addHovers = () => {
    //Seamless
    let seamlessButton = document.querySelector<HTMLElement>("#s01_text01_in");

    seamlessButton.style.cursor = "pointer";
    seamlessButton.addEventListener("mouseenter", () =>
      handleElementHover("seamlessButton")
    );

    seamlessButton.addEventListener("mouseleave", () =>
      handleElementLeave("seamlessButton")
    );

    seamlessButton.addEventListener("click", () =>
      handleElementClick("seamlessButton")
    );

    //Growth
    let growthButton = document.querySelector<HTMLElement>("#s01_text02_in");

    growthButton.style.cursor = "pointer";
    growthButton.addEventListener("mouseenter", () =>
      handleElementHover("growthButton")
    );

    growthButton.addEventListener("mouseleave", () =>
      handleElementLeave("growthButton")
    );

    growthButton.addEventListener("click", () =>
      handleElementClick("growthButton")
    );

    //tirelessButton
    let tirelessButton = document.querySelector<HTMLElement>("#s01_text03_in");

    tirelessButton.style.cursor = "pointer";
    tirelessButton.addEventListener("mouseenter", () =>
      handleElementHover("tirelessButton")
    );

    tirelessButton.addEventListener("mouseleave", () =>
      handleElementLeave("tirelessButton")
    );

    tirelessButton.addEventListener("click", () =>
      handleElementClick("tirelessButton")
    );
  };

  useEffect(() => {
    const introAnim = bodymovin.loadAnimation({
      container: introRef.current,
      renderer: "svg",
      autoplay: true,
      loop: false,

      animationData: introAnimation,
    });

    const anim = bodymovin.loadAnimation({
      container: containerRef.current,
      renderer: "svg",
      autoplay: false,
      loop: false,

      animationData: heroAnimation,
    });

    setAnimation(anim);
  }, []);

  useEffect(() => {
    if (currentScene === "initial") {
      let centerButton = document.querySelector<HTMLElement>("#s01_obj01_in");

      // centerButton.addEventListener('mouseenter', () =>
      // handleElementHover('centerButton')
      // );

      centerButton.style.cursor = "unset";
    }
  }, [currentScene]);

  useEffect(() => {
    if (animation) {
      animation.addEventListener("DOMLoaded", addHovers);
      animation.addEventListener("enterFrame", handleEnterFrame);
    }

    return () => {
      window.removeEventListener("enterFrame", handleEnterFrame);
    };
  }, [animation]);

  const onClick = (card: string) => {};

  return (
    <Container id="herocontainer">
      <HeroContent>
        <AnimatedTitle height="220px" heightMobile="120px" lineDelay={0.5}>
          <HeroTitle id="hero">
            Providing <br />
            Digital
            <br />
            Transformation
          </HeroTitle>
        </AnimatedTitle>
        <HeroSubtitle>
          The partner your business needs for digital quality solutions
        </HeroSubtitle>
      </HeroContent>
      <AnimationContainer ref={introRef}></AnimationContainer>

      <AnimationContainer
        style={{
          zIndex: "190",
        }}
        ref={containerRef}
      ></AnimationContainer>
    </Container>
  );
};
